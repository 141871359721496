import {
  useGetKycStatus,
  useOnboardingSubmit,
} from '@onefootprint/footprint-elements';
import { useTranslation } from '@onefootprint/hooks';
import { KycStatus } from '@onefootprint/types';
import { LoadingIndicator, useToast } from '@onefootprint/ui';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useEffectOnce } from 'usehooks-ts';

import useOnboardingRequirementsMachine, {
  Events,
} from '../../hooks/use-onboarding-requirements-machine';

const IdentityCheck = () => {
  const { t } = useTranslation('pages.identity-check');
  const [kycPending, setKycPending] = useState(false);
  const [state, send] = useOnboardingRequirementsMachine();
  const {
    onboardingContext: { config, authToken },
  } = state.context;
  const startKycMutation = useOnboardingSubmit();
  const toast = useToast();

  useEffectOnce(() => {
    if (!config || !authToken) {
      return;
    }
    startKycMutation.mutate(
      { authToken },
      {
        onSuccess: () => {
          setKycPending(true);
        },
        onError: handleError,
      },
    );
  });

  const handleError = () => {
    toast.show({
      title: t('error.title'),
      description: t('error.description'),
      variant: 'error',
    });
  };

  const handleKycStatus = (status: KycStatus) => {
    const isDone =
      status === KycStatus.canceled ||
      status === KycStatus.failed ||
      status === KycStatus.completed;

    if (isDone) {
      send({
        type: Events.requirementCompleted,
      });
    }
  };

  useGetKycStatus(kycPending, authToken ?? '', {
    onSuccess: response => handleKycStatus(response.status),
    onError: handleError,
  });

  return (
    <Container>
      <LoadingIndicator />
    </Container>
  );
};

const Container = styled.div`
  flex-direction: column;
  text-align: center;
  display: flex;
  min-height: var(--loading-container-min-height);
  justify-content: center;
  align-items: center;
`;

export default IdentityCheck;
